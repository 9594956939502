import keyBy from "lodash/keyBy";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppStateType } from "reducers";
import { updateTeamsInfoMapping } from "ReportsModule/actionCreators";
import * as ProfileService from "ProfileModule/services";
import * as ReportsService from "ReportsModule/services";
import * as Types from "ProfileModule/types";

export const fetchTeamMembers = createAsyncThunk(
  "fetchTeamMembers",
  async (
    { teamId }: Types.FetchTeamMembersParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;

      const response = await ProfileService.getTeamMember(
        teamId,
        {},
        auth_token
      );
      return response.data.team_members;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateTeam = createAsyncThunk(
  "updateTeam",
  async (
    params: Types.UpdateTeamParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await ReportsService.updateTeam(
        params,
        auth_token as string
      );

      params.onSuccess?.({ response });

      return response.data;
    } catch (e) {
      params.onFailure?.({ error: e });
      return rejectWithValue(e);
    }
  }
);

/* -------------------------------------------------------------------------- */

export const updateTeamMember = createAsyncThunk(
  "updateTeamMember",
  async (
    params: Types.UpdateTeamMemberParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const { teamMembershipId, onSuccess } = params;
      const response = await ProfileService.updateTeamMember(
        teamMembershipId,
        params,
        {},
        auth_token as string
      );
      onSuccess && onSuccess({ response });
      return response.data;
    } catch (e) {
      return e;
    }
  }
);

export const resetMemberPassword = createAsyncThunk(
  "resetMemberPassword",
  async (
    { emails }: Types.resetMemberPasswordParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const body = {
        emails,
      };
      const response = await ProfileService.resetMemberPassword(
        body,
        {},
        auth_token as string
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const provisionQbd = createAsyncThunk(
  "provisionQbd",
  async (
    params: Types.provisionQbdParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const token = user?.auth_token;
      const response = await ProfileService.provisionQbd(
        params,
        {},
        token as string
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchProvisionErrors = createAsyncThunk(
  "fetchProvisionErrors",
  async (
    { teamId, onSuccess, onFailure }: Types.fetchProvisionErrorsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const token = user?.auth_token;
      const response = await ProfileService.fetchProvisionErrors(
        { teamId },
        {},
        token as string
      );
      onSuccess && onSuccess({ response });
      return response.data;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);

export const provisionIntegration = createAsyncThunk(
  "provisionIntegration",
  async (
    params: Types.ProvisionIntegrationPayload,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const token = user?.auth_token;
      const response = await ProfileService.provisionIntegration(
        params,
        {},
        token as string
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const bulkActivateAccounts = createAsyncThunk(
  "bulkActivateAccounts",
  async (
    params: Types.bulkActivateAccountsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const token = user?.auth_token;
      const response = await ProfileService.bulkActivateAccounts(
        params,
        {},
        token as string
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deactivateTeams = createAsyncThunk(
  "deactivateTeams",
  async (
    { team_ids, onSuccess, skipStateUpdate }: Types.DeactivateTeamsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const token = user?.auth_token;
      const promises = team_ids.map((id) =>
        ProfileService.deactivateTeam({ team_id: id }, {}, {}, token as string)
      );
      const response = await Promise.allSettled(promises);

      onSuccess && onSuccess({ response });

      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const bulkSetUsersTemporaryPassword = createAsyncThunk(
  "bulkSetUsersTemporaryPassword",
  async (
    {
      accountIdAndPasswordPair,
      onSuccess,
      onFailure,
    }: Types.bulkSetUsersTemporaryPasswordParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const token = user?.auth_token;
      const promises = accountIdAndPasswordPair.map((item) =>
        ProfileService.setSingleUserTemporaryPassword(item, {}, token as string)
      );
      const response = await Promise.allSettled(promises);

      onSuccess && onSuccess({ response });

      return response;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);
