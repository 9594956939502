export const REPORTS_TABLE_TEAMS_PATH = "/teams";
export const REPORTS_TABLE_TEAMS_PATH_DEEP_LINK = `${REPORTS_TABLE_TEAMS_PATH}/:reportTableView`;
export const TEAM_PROFILE_PATH = "/team";
export const TEAM_PROFILE_PATH_DEEP_LINK = `${TEAM_PROFILE_PATH}/:teamId/:memberTableView`;
export const REPORTS_INTEGRATION_DETAILS_PATH = "/integration/details";
export const REPORTS_INTEGRATION_DETAILS_PATH_DEEP_LINK = `${REPORTS_INTEGRATION_DETAILS_PATH}/team/:teamId/targetService/:targetService/targetServiceId/:targetServiceId`;
export const REPORT_SUPER_ADMIN_SECTION_PATH = "/admin";
export const REPORTS_OVERALL_INTEGRATION_DETAILS_PATH = "/integration/overall";
export const REPORTS_PENDING_ENTITY_PATH = "/integration/pendingEntity";
export const LOGS_PATH = "/integration/logs";
export const INTEGRATION_HEALTH_PATH = "/integration/health";
export const INTEGRATION_HEALTH_PATH_DEEP_LINK =
  "/integration/health/team/:teamId/targetService/:targetService/targetServiceId/:targetServiceId";
export const ENTITY_ERROR_PATH = "/integration/entityErrors";
export const SANDBOXES_PATH = "/sandboxes";
export const DATA_MANAGEMENT_PATH = "/dataManagement";
export const TEAM_MANAGEMENT_PATH = "/teamManagement";
export const REPORTS_TABLE_TEAMS_V2_PATH = "/teams-retool";
export const TENANT_PATH = "/tenants";
export const SUBSCRIPTION_REALMS_PATH = "/subscriptions";
export const PRICING_CALCULATOR_PATH = "/pricing-calculator";
export const REPORT_UTILIZATION_PATH = "/reports/utilization";
export const TENANT_AND_REALM_MANAGEMENT_PATH = '/tenant-and-realm-management';
