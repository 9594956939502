import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import { standardTagsList } from "@constants";
import { TagContainer } from "SandboxModule/styles";
import { BaseDivider } from "components/BaseDivider";
import { useCreateSubscriptionRealmForm } from "RealmModule/hooks/useCreateSubscriptionRealmForm";
import { TenantSelector } from "TenantModule/components/TenantSelector";
import { StyledDefaultPrimaryButton } from "styles";
import { Divider } from "components/material-ui-styles";
import { TagsListSectionWithInput } from "components/TagsListSectionWithInput";
import { IntegrationSecretsSection } from "RealmModule/components/IntegrationSecretsSection";
import { TenantRealmInfo } from "TenantModule/components/TenantRealmInfo";
import { IntegrationAgentsMultiSelector } from "IntegrationModule/components/IntegrationAgentsMultiSelector/IntegrationAgentsMultiSelector";
import { LinearBuffer } from "components/Progress";

export const CreateSubscriptionRealmForm: React.FC<{
  showAdvancedFields?: boolean;
  disableTenantSelector?: boolean;
  onFormSubmitSuccess?: VoidFunction;
}> = ({ showAdvancedFields, disableTenantSelector = false, onFormSubmitSuccess }) => {
  const {
    isRequesting,
    createSubscriptionRealmFields,
    onCreateSubscriptionRealmFormChange,
    onPressEnter,
    debounceHandleTeamNameChange,
    selectedTenant,
    onTenantSelectorClick,
    onSubmitCreateSubscriptionRealmForm,
    handleTagCheckboxChange,
    inputRef,
    standardTags,
    addTag,
    removeTag,
    tagsList,
    tagError,
    teamNameRef,
    teamNameInputError,
    integrationAgentNamesAndSecrets,
    onAddNewIntegrationSecret,
    removeIntegrationSecretsRow,
    handleIntegrationAgentsSecretsChange,
    integrationAgentsOverride,
    integrationAgentsSelectorProps,
  } = useCreateSubscriptionRealmForm({
    showAdvancedFields,
    onSuccess: onFormSubmitSuccess,
  });

  return (
    <form
      onSubmit={onSubmitCreateSubscriptionRealmForm}
      onKeyDown={onPressEnter}
    >
      <h3>Create Isolated Database (Realm)</h3>
      <Box gridGap={12} display={"flex"} flexDirection={"column"}>
        {disableTenantSelector ? (
          <TenantRealmInfo tenantName={selectedTenant?.tenantName} />
        ) : selectedTenant ? (
          <TenantSelector
            onClick={onTenantSelectorClick}
            tenantId={selectedTenant?.id}
          />
        ) : (
          <></>
        )}
        <TextField
          required
          inputRef={teamNameRef}
          onChange={onCreateSubscriptionRealmFormChange}
          value={createSubscriptionRealmFields.teamName}
          label="Team Name"
          name="teamName"
          type="text"
          fullWidth
          variant="outlined"
          helperText={teamNameInputError}
          error={!!teamNameInputError}
          disabled={isRequesting}
          onKeyDown={debounceHandleTeamNameChange}
        />
        {showAdvancedFields ? (
          <>
            {integrationAgentNamesAndSecrets.map((item) => (
              <IntegrationSecretsSection
                sectionId={item.id}
                integrationAgentName={item.integrationAgentName}
                secret={item.secret}
                isDisabled={isRequesting}
                removeRow={removeIntegrationSecretsRow}
                integrationAgentsOverride={integrationAgentsOverride}
                onChange={(event) =>
                  handleIntegrationAgentsSecretsChange({
                    name: event.target.name,
                    value: event.target.value,
                    id: item.id,
                  })
                }
              />
            ))}
            <StyledDefaultPrimaryButton onClick={onAddNewIntegrationSecret}>
              Add Integration Secrets
            </StyledDefaultPrimaryButton>
            <FormControlLabel
              control={
                <Checkbox
                  checked={createSubscriptionRealmFields.isFirstRealm}
                  onChange={onCreateSubscriptionRealmFormChange}
                  name="isFirstRealm"
                  color="primary"
                />
              }
              label="First Realm"
            />
          </>
        ) : (
          <IntegrationAgentsMultiSelector {...integrationAgentsSelectorProps} />
        )}
      </Box>

      <BaseDivider />

      <FormControl component="fieldset" fullWidth>
        <Box component="h3">Add tags to this team</Box>
        <TagContainer>
          <div className="initial-tag-div">
            <FormGroup>
              {standardTagsList.map((item) => (
                <FormControlLabel
                  key={`${item.value}-${
                    standardTags[item.value as keyof typeof standardTags]
                  }`}
                  control={
                    <Checkbox
                      checked={
                        standardTags[item.value as keyof typeof standardTags]
                      }
                      onChange={handleTagCheckboxChange}
                      name={item.name}
                      value={item.value}
                      disabled={isRequesting}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
          </div>
          <div className="add-tag-div">
            <TagsListSectionWithInput
              tagList={tagsList}
              onDelete={removeTag}
              inputRef={inputRef}
              onKeyDown={addTag}
              helperText={tagError}
              error={!!tagError}
              disabled={isRequesting}
              placeholder="Enter custom tag"
            />
          </div>
        </TagContainer>
      </FormControl>
      <Box>{isRequesting ? <LinearBuffer /> : null}</Box>
      <Divider my={6} />
      <StyledDefaultPrimaryButton type="submit" disabled={isRequesting}>
        Submit
      </StyledDefaultPrimaryButton>
    </form>
  );
};
