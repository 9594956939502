import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateOpenRoutes } from "actionCreators";
import { selectIntegrationAgentDomainsData } from "selectors";
import IntegrationSidebar from "components/IntegrationSidebar";
import {
  getCanSeeSandboxTab,
  getDataManagementPageUrl,
  getIsDevToolTabEnabled,
  getIsTenantEnabled,
  getSandboxesPageUrl,
  getShouldEnableIntegrationServerConnection,
  getSubscriptionRealmsPageUrl,
  getTeamManagementPageUrl,
  getTenantAndRealmManagementPageUrl,
} from "utils";
import {
  integrations,
  REPORT_SUPER_ADMIN_SECTION_PATH,
  REPORTS_OVERALL_INTEGRATION_DETAILS_PATH,
  TENANT_PATH,
} from "@constants";
import async from "../components/Async";
import { RouteChildType } from "types/routes";
import {
  teamRoutes,
  teamV2Routes,
  dashboardsRoutes,
  pagesRoutes,
  projectsRoutes,
  orderRoutes,
  invoiceRoutes,
  tasksRoutes,
  calendarRoutes,
  componentsRoutes,
  chartRoutes,
  formsRoutes,
  tablesRoutes,
  iconsRoutes,
  mapsRoutes,
  documentationRoutes,
  changelogRoutes,
  authRoutes,
  PricingToolRoutes,
} from ".";
import { useMount } from "react-use";
import { TenantsContainer } from "TenantModule/TenantsContainer";
import { SuperAdminsTableContainer } from "ReportsModule/Administration/SuperAdminsTable/SuperAdminsTableContainer";

const GeneralIntegration = async(
  () => import("IntegrationModule/General/GeneralIntegrationPage")
);

const SandboxesPageContainer = async(
  () => import("SandboxModule/SandboxesPageContainer")
);

const SubscriptionRealmsPageContainer = async(
  () =>
    import(
      "RealmModule/components/SubscriptionRealm/SubscriptionRealmsPageContainer"
    )
);
const TenantAndRealmManagementPage = async(
  () =>
    import("RealmModule/TenantAndRealmManagement/TenantAndRealmManagementPage")
);
const DataManagement = async(
  () => import("ReportsModule/Developer/DataManagement/DataManagementPage")
);
const TeamManagement = async(
  () => import("ReportsModule/Developer/TeamManagement/TeamManagementPage")
);

export const useRoutes = () => {
  const {
    isRequesting: isLoadingAgentDomains,
    data: agentDomains,
  } = useSelector(selectIntegrationAgentDomainsData);
  const dispatch = useDispatch();
  const isDevToolTabEnabled = getIsDevToolTabEnabled();

  const devRoutes = useMemo(() => {
    return isDevToolTabEnabled
      ? {
          id: "Dev Tools",
          icon: <></>,
          prod: true,
          children: [
            {
              path: getDataManagementPageUrl(),
              name: "Data Managment",
              component: DataManagement,
              prod: true,
              // disabled: !isDevToolTabEnabled,
            },
            {
              path: getTeamManagementPageUrl(),
              name: "Team Managment",
              // disabled: !isDevToolTabEnabled,
              component: TeamManagement,
              prod: true,
            },
          ],
        }
      : {};
  }, [isDevToolTabEnabled]);

  const adminRoutes = useMemo(() => {
    return {
      id: "Administration",
      icon: <></>,
      prod: true,
      children: [
        {
          path: REPORT_SUPER_ADMIN_SECTION_PATH,
          name: "Super Admins",
          component: SuperAdminsTableContainer,
          prod: true,
        },
        ...(getIsTenantEnabled()
          ? [
              {
                name: "Tenant",
                prod: true,
                path: TENANT_PATH,
                component: TenantsContainer,
              },
            ]
          : []),
        {
          name: "Subscription",
          prod: true,
          path: getSubscriptionRealmsPageUrl(),
          component: SubscriptionRealmsPageContainer,
        },
        ...(getCanSeeSandboxTab()
          ? [
              {
                name: "Sandbox",
                prod: true,
                path: getSandboxesPageUrl(),
                component: SandboxesPageContainer,
              },
            ]
          : []),
        ...(getIsTenantEnabled()
          ? [
              {
                name: "Tenant & Realm Management",
                prod: true,
                path: getTenantAndRealmManagementPageUrl(),
                component: TenantAndRealmManagementPage,
              },
            ]
          : []),
      ],
    };
  }, []);

  const integrationRoutes = useMemo(() => {
    // If agentDomains not loaded, then show everything!!
    const integrationRoutesChildren = integrations.reduce<RouteChildType[]>(
      (acc, integration) => {
        // if integration.id in agentDmaoins
        if (isLoadingAgentDomains || agentDomains[integration.id]) {
          acc.push({
            path: REPORTS_OVERALL_INTEGRATION_DETAILS_PATH,
            name: "",
            prod: true,
            component: GeneralIntegration,
            integrationId: integration.id,
            CustomSidebarComponent: IntegrationSidebar,
          });
        }

        return acc;
      },
      []
    );

    return {
      id: "Integrations",
      icon: <></>,
      prod: true,
      children: integrationRoutesChildren,
    };
  }, [agentDomains, isLoadingAgentDomains]);

  const integrationRoutesToUse = useMemo(() => {
    if (getShouldEnableIntegrationServerConnection()) {
      return [integrationRoutes];
    }
    return [];
  }, [integrationRoutes]);

  const routes = useMemo(
    () => [
      teamRoutes,
      ...integrationRoutesToUse,
      teamV2Routes,
      PricingToolRoutes,
      devRoutes,
      adminRoutes,
      dashboardsRoutes,
      /* -------------------------------------------------------------------------- */
      pagesRoutes, //
      projectsRoutes,
      orderRoutes,
      invoiceRoutes,
      tasksRoutes,
      calendarRoutes,
      componentsRoutes,
      chartRoutes,
      formsRoutes,
      tablesRoutes,
      iconsRoutes,
      mapsRoutes,
      documentationRoutes,
      changelogRoutes,
    ],
    [adminRoutes, devRoutes, integrationRoutesToUse]
  );

  const sidebarRoutes = useMemo(
    () => [
      teamRoutes,
      teamV2Routes,
      PricingToolRoutes,
      ...integrationRoutesToUse,
      devRoutes,
      adminRoutes,
      /* -------------------------------------------------------------------------- */
      dashboardsRoutes,
      projectsRoutes,
      orderRoutes,
      invoiceRoutes,
      tasksRoutes,
      calendarRoutes,
      authRoutes,
      componentsRoutes,
      chartRoutes,
      formsRoutes,
      tablesRoutes,
      iconsRoutes,
      mapsRoutes,
      documentationRoutes,
      changelogRoutes,
    ],
    [adminRoutes, devRoutes, integrationRoutesToUse]
  );

  useMount(() => {
    const defaultOpenRoutes = sidebarRoutes.reduce<Record<string, boolean>>(
      (acc, route) => {
        if (route.id) {
          acc[route.id] = true;
        }
        return acc;
      },
      {}
    );
    dispatch(updateOpenRoutes(defaultOpenRoutes));
  });

  return { routes, sidebarRoutes };
};
