import { AppStateType } from "reducers";
import { orderBy, groupBy, partition } from "lodash";
import { createSelector } from "reselect";

import { MAPPING_STATUS, DATA_TYPES, filterIds } from "@constants";
import { selectSelectedTargetService } from "selectors";
import * as thunks from "thunk";
import { Integration } from "types";
import moment from "moment";

const emptyObj = {};
const emptyArr: never[] = [];

const selectOwnTeamId = (state: AppStateType, ownProps: { teamId?: number }) =>
  ownProps?.teamId;

const selectOwnTargetServiceId = (
  state: AppStateType,
  ownProps: { targetServiceId?: number }
) => ownProps?.targetServiceId;

export const selectIntegrationRequestStatusesAndErrors = (
  state: AppStateType
) => state.integrationReducer.requestStatusesAndErrors;

const parseJson = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return {};
  }
};

const selectIntegrationReducerState = (state: AppStateType) =>
  state.integrationReducer;

/* -------------------------------------------------------------------------- */

export const selectDataOverviewPerDataTypes = (state: AppStateType) =>
  state.integrationReducer.integration_data_overview_per_data_type;

export const selectDataOverviewPerDataTypesData = createSelector(
  [selectDataOverviewPerDataTypes, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => {
    return {
      data,
      ...requestStatusesAndErrors[
        thunks.fetchDataOverviewPerDataTypes.typePrefix
      ],
    };
  }
);

/* -------------------------------------------------------------------------- */

export const selectDataHistoryPerDataTypes = (state: AppStateType) =>
  state.integrationReducer.integration_data_history_per_data_type;

export const selectDataHistoryPerDataTypesData = createSelector(
  [selectDataHistoryPerDataTypes, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => {
    return {
      data,
      ...requestStatusesAndErrors[
        thunks.fetchDataOverviewPerDataTypes.typePrefix
      ],
    };
  }
);

/* -------------------------------------------------------------------------- */

export const selectIntegrationServerQueuesStatus = (state: AppStateType) =>
  state.integrationReducer.queues_status;

export const selectIntegrationServerQueuesStatusData = createSelector(
  [
    selectIntegrationServerQueuesStatus,
    selectIntegrationRequestStatusesAndErrors,
  ],
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[
      thunks.fetchIntegrationServerQueuesStatus.typePrefix
    ],
  })
);

/* ---------------------------------Pending Entities----------------------------------------- */
export const selectPendingEntitiesFilter = createSelector(
  (state: AppStateType) => state.filterReducer,
  (filters) => filters[filterIds.pendingEntities]
);

export const selectPendingEntities = (state: AppStateType) =>
  state.integrationReducer.pending_entities;

export const selectPendingEntitiesData = createSelector(
  selectPendingEntities,
  selectSelectedTargetService,
  selectPendingEntitiesFilter,
  selectIntegrationRequestStatusesAndErrors,
  (
    pendingEntities,
    selectedTargetService,
    filter,
    requestStatusesAndErrors
  ) => {
    const rawPendingEntities = selectedTargetService?.id
      ? pendingEntities[selectedTargetService?.id as number] || []
      : [];

    /** Sorting */
    const sortedPendingEntities = filter?.orderBy
      ? orderBy(rawPendingEntities, filter.orderBy, filter.orderDirection)
      : rawPendingEntities;

    /** Filtering */

    const filteredPendingEntities = filter
      ? sortedPendingEntities.slice(
          filter.page * filter.rowsPerPage,
          (filter.page + 1) * filter.rowsPerPage
        )
      : sortedPendingEntities;
    return {
      totalCounts: selectedTargetService?.id
        ? pendingEntities[selectedTargetService?.id as number]?.length || 0
        : 0,
      page: filter?.page,
      orderDirection: filter?.orderDirection,
      orderBy: filter?.orderBy,
      rowsPerPage: filter?.rowsPerPage,
      pendingEntities: filteredPendingEntities,
      ...requestStatusesAndErrors[thunks.fetchPendingEntities.typePrefix],
    };
  }
);

/* ------------------------------Api network log-------------------------------------------- */
const selectAllApiNetworkLog = (state: AppStateType) =>
  state.integrationReducer.api_request_log;

const selectAllApiNetworkLogData = createSelector(
  [selectAllApiNetworkLog, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => {
    return {
      data,
      ...requestStatusesAndErrors[thunks.fetchApiRequestLog.typePrefix],
    };
  }
);

const selectApiNetworkLogFilter = (state: AppStateType) =>
  state.filterReducer[filterIds.apiNetworkLog];

export const selectApiNetworkLogByTeam = createSelector(
  selectAllApiNetworkLogData,
  selectApiNetworkLogFilter,
  (allApiNetworkLogs, filter) => {
    const { data, isRequesting, error } = allApiNetworkLogs;
    const { currentApiRequestId, currentMosaicTeamId } = data;

    if (!currentApiRequestId || !currentMosaicTeamId)
      return {
        formattedData: [],
        filter,
        error: undefined,
        isRequesting: true,
      };

    /* ---------------------------------- Error --------------------------------- */

    const errorByRequestId = (error as Record<number, any>)[
      currentMosaicTeamId
    ]?.[currentApiRequestId];

    if (errorByRequestId?.message)
      return {
        formattedData: [],
        filter,
        error: errorByRequestId,
        isRequesting: false,
      };

    /* ---------------------------------- Requesting status --------------------------------- */

    const IsRequestInProgress = (isRequesting as Record<number, any>)[
      currentMosaicTeamId
    ]?.[currentApiRequestId];

    const apiRequestLogByTeam =
      allApiNetworkLogs &&
      +currentMosaicTeamId &&
      currentApiRequestId &&
      data[currentMosaicTeamId]?.[currentApiRequestId]
        ? data[currentMosaicTeamId][currentApiRequestId]
        : [];

    const parsedApiRequestLogData = apiRequestLogByTeam.map((data) => {
      const parsedMetaData = data.metadata ? parseJson(data.metadata) : {};

      return {
        ...data,
        ...parsedMetaData,
      };
    });

    /** Sorting */
    const sortedApiNetworkLog = orderBy(
      parsedApiRequestLogData,
      filter.orderBy,
      filter.orderDirection
    );

    return {
      formattedData: sortedApiNetworkLog,
      filter,
      error,
      isRequesting: IsRequestInProgress,
    };
  }
);

/* ------------------------------Network log-------------------------------------------- */

const selectAllNetworkLog = (state: AppStateType) =>
  state.integrationReducer.network_log;

const selectAllNetworkLogData = createSelector(
  [selectAllNetworkLog, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => {
    return {
      data,
      ...requestStatusesAndErrors[thunks.fetchNetworkLog.typePrefix],
    };
  }
);

const selectNetworkLogByTeamFilter = (state: AppStateType) =>
  state.filterReducer[filterIds.networkLogByTeam];

export const selectNetworkLogByTeam = createSelector(
  selectAllNetworkLogData,
  selectNetworkLogByTeamFilter,
  (allNetworkLogs, filter) => {
    const { data, isRequesting, error } = allNetworkLogs;
    const { currentSessionId, currentMosaicTeamId } = data;

    if (!currentSessionId || !currentMosaicTeamId)
      return {
        formattedData: [],
        filter,
        error,
        isRequesting: true,
      };

    /* ---------------------------------- Error --------------------------------- */

    const errorBySessionId = (error as Record<number, any>)[
      currentMosaicTeamId
    ]?.[currentSessionId];

    if (errorBySessionId?.message)
      return {
        formattedData: [],
        filter,
        error: errorBySessionId,
        isRequesting: false,
      };

    const networkLogByTeam =
      allNetworkLogs &&
      currentMosaicTeamId &&
      currentSessionId &&
      data[currentMosaicTeamId]?.[currentSessionId]
        ? data[currentMosaicTeamId][currentSessionId]
        : [];

    /* ----------------------------- Request Status ----------------------------- */
    const IsRequestInProgress = (isRequesting as Record<number, any>)[
      currentMosaicTeamId
    ]?.[currentSessionId];

    /** Sorting */
    const sortedNetworkLogByTeam = orderBy(
      networkLogByTeam,
      filter.orderBy,
      filter.orderDirection
    );

    return {
      formattedData: sortedNetworkLogByTeam,
      filter,
      error: errorBySessionId,
      isRequesting: IsRequestInProgress,
    };
  }
);

/* ------------------------- Team Network Error Log (IS) ------------------------ */

const selectTeamNetworkErrorLogFilter = (state: AppStateType) =>
  state.filterReducer[filterIds.teamNetworkErrorLog];

const selectTeamNetworkErrorLogState = (state: AppStateType) =>
  state.integrationReducer.team_network_error_log;

const selectTeamNetworkErrorLogData = createSelector(
  [selectTeamNetworkErrorLogState, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchTeamNetworkErrorLog.typePrefix],
  })
);

export const selectTeamNetworkErrorLog = createSelector(
  selectTeamNetworkErrorLogData,
  selectTeamNetworkErrorLogFilter,
  (teamNetworkErrorLog, filter) => {
    const { data, isRequesting, error } = teamNetworkErrorLog;

    if (!data || data.length === 0 || isRequesting || error?.message) {
      return {
        formattedData: [],
        filter,
        fetchedSoFar: 0,
        isRequesting,
        error,
        total: data && data.length > 0 ? data[0].total_rows : 0,
      };
    }

    /** Parse meta data */

    const parsedTeamNetworkErrorLog = data.map((data) => {
      const parsedMetaData = data.metadata ? parseJson(data.metadata) : {};
      const parsedError = data.error ? parseJson(data.error) : {};

      return {
        ...data,
        ...parsedMetaData,
        "Meta Data": parsedMetaData,
        "All Errors": parsedError,
      };
    });

    /** Sorting */
    const sortedData = orderBy(
      parsedTeamNetworkErrorLog,
      filter.orderBy,
      filter.orderDirection
    );

    // /** Slicing */
    const startIdx = filter.offset;
    const endIdx = filter.offset + filter.limit;
    const formattedData = sortedData.slice(startIdx, endIdx);

    return {
      filter,
      total: formattedData[0].total_rows,
      formattedData,
      fetchedSoFar: data.length,
      isRequesting,
      error,
    };
  }
);

/* ------------------------- Team Agent Error / Recent Errors Log (Agent) ------------------------ */

export const selectTeamAgentErrorsFilter = createSelector(
  (state: AppStateType) => state.filterReducer,
  (filters) => filters[filterIds.teamAgentErrorLog]
);

const selectTeamAgentErrorsState = (state: AppStateType) =>
  state.integrationReducer.team_agent_error_log;

const selectTeamAgentErrorsData = createSelector(
  [selectTeamAgentErrorsState, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchTeamAgentErrorLog.typePrefix],
  })
);

export const selectTeamAgentErrors = createSelector(
  selectTeamAgentErrorsData,
  selectTeamAgentErrorsFilter,
  (teamAgentErrors, filter) => {
    const { data, isRequesting, error } = teamAgentErrors;

    if (!data || data.length === 0 || isRequesting || error?.message) {
      return {
        formattedData: [],
        filter,
        fetchedSoFar: 0,
        isRequesting,
        error,
        total: data && data.length > 0 ? data[0]?.total_rows : 0,
      };
    }
    /** Sorting */
    const sortedData = orderBy(data, filter.orderBy, filter.orderDirection);

    /** Slicing */
    const startIdx = filter.offset;
    const endIdx = filter.offset + filter.limit;
    const formattedData = sortedData.slice(startIdx, endIdx);

    return {
      filter,
      total: formattedData[0].total_rows,
      formattedData,
      fetchedSoFar: data.length,
      isRequesting,
      error,
    };
  }
);

/* -------------------------------------------------------------------------- */

export const selectSingleEntity = (state: AppStateType) =>
  state.integrationReducer.single_entity;

export const selectSingleEntityData = createSelector(
  selectSingleEntity,
  selectIntegrationRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchSingleEntity.typePrefix],
  })
);

export const selectLastTimeSync = (state: AppStateType) =>
  state.integrationReducer.last_time_sync;

export const selectLastTimeSyncData = createSelector(
  [selectLastTimeSync, selectIntegrationRequestStatusesAndErrors],
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchLastTimeSync.typePrefix],
  })
);

/* ----------------------------Integration Notes---------------------------------------------- */

const selectIntegrationsByTeamIdState = (state: AppStateType) =>
  state.integrationReducer.integrationsByTeamId;

const selectAllIntegrationByTeamId = createSelector(
  selectIntegrationReducerState,
  (state) => state.integrationsByTeamId
);

// Assumption is there is only at most 1 active integration per target service id group (checked)
const findMostRecentlyArchivedIntegrationAndOnlyActiveIntegrationInTargetServiceIdGroup = (
  integrationsInTargetServiceIdGroup?: Integration[]
) => {
  let activeIntegration: Integration | undefined;
  let mostRecentlyArchivedIntegration: Integration | undefined;

  if (!integrationsInTargetServiceIdGroup)
    return {
      activeIntegration,
      mostRecentlyArchivedIntegration,
    };

  integrationsInTargetServiceIdGroup.forEach((integration) => {
    if (!integration.isArchived) {
      // found active integration
      activeIntegration = integration;
    } else {
      // unarchived
      if (!mostRecentlyArchivedIntegration) {
        // Defined if not exist yet
        mostRecentlyArchivedIntegration = integration;
      } else {
        // already exist, check which one is most recently archived

        mostRecentlyArchivedIntegration = moment(
          integration.archivedAt
        ).isAfter(mostRecentlyArchivedIntegration.archivedAt)
          ? integration
          : mostRecentlyArchivedIntegration;
      }
    }
  });

  return {
    activeIntegration,
    mostRecentlyArchivedIntegration,
  };
};

/**
 * Spec for this selector
 * Per target service id group of each team
    If there is an active one, show it
    If there is no active one, show the archived one that is the most recently archived.
   Show active integrations at top, then archived ones
 */
export const selectFilteredIntegrationsListByTeams = createSelector(
  selectAllIntegrationByTeamId,
  (allIntegrationsByTeamId) => {
    return Object.keys(allIntegrationsByTeamId).reduce(
      (acc: Partial<Record<number, Integration[]>>, teamId) => {
        const numericTeamId = +teamId;
        const allIntegrationsOfCurrentTeam =
          allIntegrationsByTeamId[numericTeamId];

        const allIntegrationsByTargetServiceIdOfCurrentTeam = groupBy(
          Object.values(allIntegrationsOfCurrentTeam),
          "targetServiceId"
        );

        if (!acc[numericTeamId]) {
          acc[numericTeamId] = [];
        }

        Object.values(allIntegrationsByTargetServiceIdOfCurrentTeam).forEach(
          (integrationsWithSharedTargetServiceId) => {
            const {
              activeIntegration,
              mostRecentlyArchivedIntegration,
            } = findMostRecentlyArchivedIntegrationAndOnlyActiveIntegrationInTargetServiceIdGroup(
              integrationsWithSharedTargetServiceId
            );

            if (activeIntegration) {
              acc[numericTeamId]!.push(activeIntegration);
            } else if (mostRecentlyArchivedIntegration) {
              acc[numericTeamId]!.push(mostRecentlyArchivedIntegration);
            }
          }
        );

        // Reordered active ones to top
        const [archived, active] = partition(acc[numericTeamId], "isArchived");

        acc[numericTeamId] = [...active, ...archived];

        return acc;
      },
      {}
    );
  }
);

export const selectFilteredIntegrationsList = createSelector(
  selectFilteredIntegrationsListByTeams,
  selectOwnTeamId,
  (filteredIntegrationsListByTeams, teamId) =>
    teamId ? filteredIntegrationsListByTeams[+teamId] || emptyArr : emptyArr
);

export const makeGetSelectIntegrationsArrayByTeamIdData = () =>
  createSelector(
    selectIntegrationsByTeamIdState,
    selectOwnTeamId,
    selectIntegrationRequestStatusesAndErrors,
    (integrationByTeamID, teamId, requestStatusesAndErrors) => {
      const data = teamId
        ? Object.values(integrationByTeamID[teamId] || {})
        : undefined;

      return {
        data,
        ...requestStatusesAndErrors[thunks.fetchIntegrations.typePrefix],
      };
    }
  );

export const makeGetSelectIntegrationByTeamIdAndTargetServiceId = () =>
  createSelector(
    selectIntegrationsByTeamIdState,
    selectOwnTeamId,
    selectOwnTargetServiceId,
    selectIntegrationRequestStatusesAndErrors,
    (
      integrationByTeamID,
      teamId,
      targetServiceId,
      requestStatusesAndErrors
    ) => {
      const data =
        teamId && targetServiceId
          ? integrationByTeamID[teamId]?.[targetServiceId]
          : undefined;

      return {
        data,
        ...requestStatusesAndErrors[thunks.fetchIntegrations.typePrefix],
      };
    }
  );
