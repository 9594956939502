import { useState } from "react";
import { useArrayItemsInput } from "./useArrayItemsInput";
import { checkTagsInputError } from "utils/tag";
import { standardTagsHash, StandardTag } from "@constants";

const standardTagsCheckboxInitialState = {
  [StandardTag.Over50]: false,
  [StandardTag.Over100]: false,
  [StandardTag.SelfServe]: false,
};

const tagInListErrorMessage = "The tag already exist";

const checkItemValidity: Parameters<
  typeof useArrayItemsInput
>[0]["checkItemValidity"] = ({ item, itemsList }) => {
  const trimmedItem = item.trim();
  const isEmptyText = !trimmedItem;
  const lowerCaseItem = trimmedItem.toLocaleLowerCase();
  const tagInputErrorMessage = checkTagsInputError(trimmedItem);
  const existInTheList = itemsList.indexOf(lowerCaseItem) !== -1;

  const { errorMessage, isValid } = tagInputErrorMessage
    ? { errorMessage: tagInputErrorMessage, isValid: false }
    : existInTheList
    ? { errorMessage: tagInListErrorMessage, isValid: false }
    : isEmptyText
    ? { errorMessage: "Please enter a value", isValid: false }
    : { errorMessage: undefined, isValid: true };

  const itemToAdd = trimmedItem; // Intended to pass in trimmed string

  return {
    isValid,
    errorMessage,
    itemToAdd,
  };
};

export const useArrayTagsInput = () => {
  const [standardTags, setStandardTags] = useState(
    standardTagsCheckboxInitialState
  );

  const clearStandardTags = () => {
    setStandardTags(standardTagsCheckboxInitialState);
  };

  const {
    addItem,
    updateItemList,
    removeItem,
    inputRef,
    itemsList,
    error: tagError,
    setItemsList,
    clearErrorAndItems,
  } = useArrayItemsInput({
    checkItemValidity,
  });

  const removeTag = (tag: string) => {
    removeItem(tag);
    const isStandard = !!standardTagsHash[tag];

    if (isStandard) {
      setStandardTags((prev) => ({ ...prev, [tag]: false }));
    }
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<{ name: string; checked: boolean; value: string }>
  ) => {
    if (event.target.checked) {
      setStandardTags({
        ...standardTags,
        [event.target.name]: event.target.checked,
      });
      updateItemList(event.target.value);
    } else {
      removeTag(event.target.value);
    }
  };

  return {
    handleCheckboxChange,
    standardTags,
    setStandardTags,
    inputRef,
    tagError,
    clearStandardTags,
    addTag: addItem,
    removeTag,
    updateTagList: updateItemList,
    setTagsList: setItemsList,
    tagsList: itemsList,
    clearErrorAndTags: clearErrorAndItems,
  };
};
